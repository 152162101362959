













































































import {Vue, Component, Mixins} from 'vue-property-decorator';
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import {SingleItem} from "@/handlers/interfaces/ContentUI";
import Headquarter from "@/models/Headquarter";
import OrderDataTab from "@/components/tabs/order/OrderDataTab.vue";
import HeadquarterOrder from "@/models/HeadquarterOrder";
import OrderDetailListComponent from "@/components/OrderDetailListComponent.vue";
import SessionModule from "@/store/SessionModule";
import OrderDetail from "@/models/OrderDetail";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import HeadquarterOrderDataTab from "@/components/HeadquarterOrderDataTab.vue";
import CreateHeadquarterDialog from "@/components/dialog/CreateHeadquarterDialog.vue";
import OrderAddProductsTab from "@/components/tabs/order/OrderAddProductsTab.vue";
import Product from "@/models/Product";
import HeadquarterOrderDetail from "@/models/HeadquarterOrderDetail";
import SnackbarModule from "@/store/SnackbarModule";
import Handler from "@/handlers/Handler";
import OrderService from "@/services/OrderService";
import HeadquarterOrderService from "@/services/HeadquarterOrderService";
import SessionMixin from "@/mixins/SessionMixin";

@Component({
  components: {OrderAddProductsTab, CreateHeadquarterDialog, HeadquarterOrderDataTab, OrderDetailListComponent}
})
export default class CreateHeadquarterOrderView extends Mixins(SessionMixin) {

  get lang() { return getModule(LangModule).lang }

  sessionModule: SessionModule = getModule(SessionModule)
  loading: boolean = false
  pvp: boolean = false
  tab: number = 0

  headquarterOrder: SingleItem<HeadquarterOrder> = { item: new HeadquarterOrder() }

  async created() {
    if (this.$route.params.id) {
      await this.getHeadquarterOrder()
    }
  }

  onQuantityChanged(orderDetail: OrderDetail) {
    let index = this.headquarterOrder.item.headquarterOrderDetails?.indexOf(orderDetail)
    this.headquarterOrder.item.headquarterOrderDetails![index!].quantity = orderDetail.quantity
  }

  removeOrderDetail(orderDetail: OrderDetail) {
    getModule(DialogModule).showDialog(new Dialog(
        this.lang.warning, this.lang.removeProductFromOrderConfirmation, () => {
          this.headquarterOrder.item.headquarterOrderDetails = this.headquarterOrder.item.headquarterOrderDetails?.filter(od => od.product!!.id != orderDetail.product!!.id)
        }
    ))
  }

  isExistingOrder() {
    return this.$route.params.orderId != null
  }

  get headquarterOrderRateId() {
    return this.isExistingOrder() ? this.headquarterOrder.item.creationRate!! : this.sessionModule.session.user.rateId!!
  }

  get getTotalPrice() {
    let total: number = 0
    this.headquarterOrder.item.headquarterOrderDetails!!.forEach(headquarterOrderDetail => {
      if (this.pvp) {
        total += (Number(headquarterOrderDetail.product!.commercialRate) * Number(headquarterOrderDetail.quantity))
      } else {
        total += (Number(headquarterOrderDetail.product!.rates![this.headquarterOrderRateId - 1].price) * Number(headquarterOrderDetail.quantity))
      }

    })
    return total.toFixed(2)
  }

  addOrderDetail(product: Product) {
    //Si el producto ya ha sido añadido anteriormente, isAlreadyAdded sería -1.
    let isAlreadyAdded = this.headquarterOrder.item.headquarterOrderDetails?.findIndex(od => od.product?.id == product.id)

    if (isAlreadyAdded != -1) {
      getModule(DialogModule).showDialog(new Dialog(
          this.lang.warning, this.lang.alreadyAddedProductMessage,
          async () => {
            if (product.quantity == 0) {
              this.headquarterOrder.item.headquarterOrderDetails![isAlreadyAdded!].quantity = 1
              product.quantity = 1
            } else {
              this.headquarterOrder.item.headquarterOrderDetails![isAlreadyAdded!].quantity = product.quantity
            }
          }
      ))
    } else {
      const headquarterOrderDetail = new HeadquarterOrderDetail()
      headquarterOrderDetail.product = product
      if (product.quantity == 0) {
        headquarterOrderDetail.quantity = 1
        product.quantity = 1
      } else {
        headquarterOrderDetail.quantity = product.quantity
      }

      this.headquarterOrder.item.headquarterOrderDetails?.push(headquarterOrderDetail)
    }
  }

  async sendOrderConfirmation() {
    if (this.validateOrder()) {
      if (this.headquarterOrderIsEmpty) {
        getModule(SnackbarModule).makeToast(this.lang.addProductToContinue)
      } else {
        getModule(DialogModule).showDialog(new Dialog(
            this.lang.warning, this.$route.params.orderId ? this.lang.sendOrderToCentralQuestion : this.lang.newlyOrderSendWarning,
            async () => {
              await this.sendOrder()
              await this.$router.push("/headquarter-orders")
            }))
      }
    }
  }

  async saveAnOrder() {
    this.removeNullOrderDetails()
    if (this.validateOrder()) {
      if (this.headquarterOrderIsEmpty) {
        getModule(SnackbarModule).makeToast(this.lang.addProductToContinue)
      } else {
        getModule(DialogModule).showDialog(new Dialog(this.lang.warning, this.lang.saveOrderQuestion,
            async () => {
              await this.patchOrder()
              await this.$router.push("/headquarter-orders")
            }
        ))
      }
    }
  }

  validateOrder() {
    // @ts-ignore
    return this.$refs.orderDataTab.validateForm()
  }

  async draft() {     //Use this to create orders
    await Handler.getItem(this, this.headquarterOrder, () => HeadquarterOrderService.draft(this.headquarterOrder.item))
  }

  async patchOrder() {
    await Handler.getItem(this, this.headquarterOrder, () =>
        HeadquarterOrderService.patch(this.headquarterOrder.item.id!!, this.headquarterOrder.item)
    )
  }

  async sendOrder() {
    if (this.headquarterOrder.item.id == null) {
      await this.draft()
    }
    await this.patchOrder()
    await Handler.getItem(this, this.headquarterOrder, () => HeadquarterOrderService.send(this.headquarterOrder.item.id!))
  }

  async createOrderConfirmation() {
    this.removeNullOrderDetails()
    if (this.validateOrder()) {
      if (this.headquarterOrderIsEmpty) {
        getModule(SnackbarModule).makeToast(this.lang.addProductToContinue)
      } else {
        getModule(DialogModule).showDialog(new Dialog(this.lang.warning, this.lang.placeOrderQuestion,
            async () => {
              await this.draft()
              await this.$router.push("/headquarter-orders")
            }
        ))
      }
    }
  }

  get headquarterOrderIsEmpty(): boolean {
    return this.headquarterOrder.item.headquarterOrderDetails?.length == 0
  }

  removeNullOrderDetails() {
    this.headquarterOrder.item.headquarterOrderDetails = this.headquarterOrder.item.headquarterOrderDetails?.filter(detail => detail.quantity != 0)
  }

  async getHeadquarterOrder() {
    await Handler.getItem(this, this.headquarterOrder, () =>
        HeadquarterOrderService.getById(Number(this.$route.params.id))
    )
  }

}
