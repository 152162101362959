





























































































import {Vue, Component, PropSync, Ref, Watch, Mixins} from 'vue-property-decorator';
import Address, {AddressParentTypes} from "@/models/Address";
import Client from "@/models/Client";
import Headquarter from "@/models/Headquarter";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import Rules from "@/services/tool/Rules";
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import Handler from "@/handlers/Handler";
import HeadquarterService from "@/services/HeadquarterService";
import SessionMixin from "@/mixins/SessionMixin";
import AddressService from "@/services/AddressService";
import CreateHeadquarterDialog from "@/components/dialog/CreateHeadquarterDialog.vue";
import CreateAddressDialog from "@/components/dialog/CreateAddressDialog.vue";

@Component({
computed: { AddressParentTypes() {
return AddressParentTypes} } ,
  components: {CreateAddressDialog, CreateHeadquarterDialog}
})
export default class HeadquarterOrderDataTab extends Mixins(SessionMixin) {

  @Ref() readonly form!: HTMLFormElement

  get rules() {
    return Rules
  }

  @PropSync('address') addressModel!: Nullable<Address>
  @PropSync('headquarter') headquarterModel!: Nullable<Headquarter>
  @PropSync('reference') syncedReference!: Nullable<string>
  @PropSync('description') syncedDescription!: Nullable<string>

  headquarters: MultipleItem<Headquarter> = { items: [], totalItems: 0 }
  addresses: MultipleItem<Address> = {items: [], totalItems: 0}
  loading: boolean = false
  headquarterSearch: string = ""
  headquarterDialog: boolean = false
  addressDialog: boolean = false
  pvp: boolean = false

  lang: any = getModule(LangModule).lang

  created() {
    this.refresh()
  }

  async refresh() {
    await this.getHeadquarters()
  }

  async getHeadquarters() {
    await Handler.getItems(this, this.headquarters, () =>
        HeadquarterService.findAll(0, 10)
    )
  }

  @Watch('headquarterModel', {deep: true})
  async getAddresses() {
    if (this.headquarterModel?.id != null) {
      await Handler.getItems(this, this.addresses, () =>
          AddressService.getHeadquarterAddresses(this.headquarterModel?.id!!)
      )
    }
  }

  afterCreatedHeadquarter(headquarter: Headquarter) {
    this.refresh()
    this.headquarterModel = headquarter
    this.headquarterDialog = false
  }

  validateForm() {
    return this.form.validate()
  }

}
